body .c-dialog {
  &__title {
    display: grid;
    grid-template-columns: 1fr auto;
    padding-left: 20px;
    padding-right: 12px;
    align-items: center;
  }
  &__content,
  &__actions {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__content {
    .form {
      &__fields {
        display: grid;
        grid-gap: 20px;
      }
      &__success {
        &_btn {
          justify-content: center;
        }
      }
    }
    &.success {
      .c-dialogIcon__icon {
        background: #62ca7a;
        border-color: #ecfdf3;
        svg {
          color: #fff;
        }
      }
    }
  }
  &__btns {
    // margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .auth__title {
    font-size: 24px;
  }
  &.c-dialogIcon {
    .c-dialog__content {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
  &__text {
    font-size: 14px;
    color: $greyTitle;
  }
}

.c-dialogIcon {
  &__icon {
    background: #fee4e2;
    width: 48px;
    height: 48px;
    border: 8px solid #fef3f2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: #d92d20;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 24px;
    margin-bottom: 24px;
  }
  &__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.MuiDialogContent-root {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
}

.MuiDialogContent-root::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.reprocessForm__selected {
  display: grid;
  grid-gap: 10px;
  margin: 15px 0;
  p {
    font-size: 12px;
    .active {
      color: $green;
    }
  }
}
