.snackbar {
  &.success {
    .MuiPaper-root {
      background: #ecfdf3;
    }
    .snackbar__icon {
      background: #62ca7a;
    }
  }
  &.error {
    .snackbar__icon {
      background: #f97066;
    }
    .snackbar__text {
      color: #7a271a;
    }
  }
  &__icon {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    svg {
      color: white;
      width: 70%;
      height: auto;
    }
  }
  .MuiPaper-root {
    padding: 10px 13px;
    background: white;
  }
  .MuiSnackbarContent-message {
    padding: 0;
  }
  &__content {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
  }
  &__text {
    color: black;
  }
}
