.activity {
  border-top: 1px solid #e5e7eb;
  padding-top: 12px;
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
  &__item {
    padding: 16px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    display: grid;
    grid-gap: 20px;
    align-items: center;
    grid-template-columns: 1fr auto;
    &-check {
      width: 56px;
      height: 44px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fee4e2;
      svg {
        color: #d92d20;
      }
      &.checked {
        background: #aee9d1;
        svg {
          color: #005a43;
        }
      }
    }
  }
}
