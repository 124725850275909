.userRank {
  max-width: 163px;
  margin: auto;
  position: relative;
  img {
    height: 259px;
    width: 100%;
  }
  &.amateur {
    .userRank__avatar {
      background: #e28a6a;
      border-color: #ffc400;
    }
    .line {
      background: #b04a00;
    }
    .userRank__title {
      border-color: rgb(176, 74, 0);
    }
  }
  &.unranked {
    .userRank__avatar {
      background: #2c2c2c;
      border-color: #565656;
    }
    .line {
      background: rgba(255, 255, 255, 0.4);
    }
    .userRank__title {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
  &.semi-pro {
    .userRank__avatar {
      background: #c4c4c4;
      border-color: #fff;
    }
    .line {
      background: #bcbcbc;
    }
    p {
      color: #4e4e4e;
    }
    .userRank__st {
      p {
        color: #fff;
      }
      .line {
        background: #4e4e4e;
      }
    }
    .userRank__title {
      color: #fff;
      border-color: rgba(78, 78, 78, 0.25);
    }
  }
  &.professional {
    .userRank__title {
      color: #fff;
      border-color: rgba(78, 78, 78, 0.25);
    }
    .userRank__avatar {
      background: #efdb8d;
      border-color: #b9a252;
    }
    p {
      color: #493d0e;
    }
    .line {
      background: #c0af64;
    }
  }
  &.world-class {
    p {
      color: #072d34;
    }
    .userRank__st .line {
      background: #072d34;
    }
    .line {
      background: #1aa79b;
    }
    .userRank__title {
      color: white;
    }
    .userRank__avatar {
      border-color: #179b9d;
      background: #24fbff;
    }
  }
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 16px;
    line-height: 100%;
    text-transform: uppercase;
    p {
      font-family: "Oswald", sans-serif;
      color: white;
    }
  }
  &__title {
    margin: 0 auto 10px;
    width: max-content;
    font-weight: 500;
    letter-spacing: 1.5px;
    font-size: 10px;
    border-radius: 2px;
    padding: 2px;
    background-color: rgba(0, 0, 0, 0.15);
    border-width: 1px;
    border-style: solid;
  }
  &__avatar {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    img {
      width: 100% !important;
      height: 100% !important;
      border-radius: 50%;
    }
  }
  &__st {
    text-align: center;
    p {
      line-height: 120%;
    }
    p:first-child {
      font-size: 32px;
      font-weight: bold;
    }
    p:last-child {
      font-size: 14px;
      font-weight: 500;
    }
    .line {
      height: 1px;
      width: 24px;
      margin: 6px auto;
    }
  }
  &__stats {
    margin-top: 20px;
    &-title {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
    }
    &-line {
      width: 100%;
      height: 1px;
      margin: 6px 0;
    }
    &-list {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      gap: 20px;
      p {
        font-size: 11px;
        display: flex;
      }
      .line {
        width: 1px;
      }
      .MuiSvgIcon-root {
        width: 10px;
        height: auto;
        position: relative;
        top: 0.5px;
        margin-right: 2px;
      }
    }
    &-block {
      display: grid;
      gap: 3px;
    }
  }
  &__line {
    width: 24px;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
  }
}
