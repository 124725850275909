.auth {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roobert", sans-serif;
  line-height: 1.2;

  .MuiInputBase-input {
    padding: 12px 0 8px;
  }

  .MuiCircularProgress-progress {
    stroke: black;
  }

  .MuiCircularProgress-track {
    stroke: transparent;
  }

  .rs-form-group {
    margin-bottom: 0 !important;
  }

  &-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    display: none;
    background-color: #e2e8f0;

    @media (min-width: 768px) {
      display: block;
    }
  }

  &__block {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100vh;
    height: 100%;
    padding: 25px 15px;

    @media (min-width: 768px) {
      height: auto;
      min-height: auto;
      max-width: 604px;
      padding: 32px;
      display: block;
    }
  }

  &__heading {
    margin-bottom: 32px;

    &-row {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 12px;

      img {
        width: 76px;
        height: auto;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 26px;

    @media (min-width: 640px) {
      font-size: 36px;
    }
  }

  &__subtitle {
    color: #70707b;
    margin-top: 12px;
    font-size: 16px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    input {
      font-size: 18px;

      &::placeholder {
        color: #4b5565;
        opacity: 1;
        font-family: "Roobert", sans-serif;
      }
    }

    &-label {
      font-size: 14px;
      color: #4b5565;
      margin-bottom: 6px;
    }
  }

  &__link {
    color: $linkColor;
    font-size: 14px;
    cursor: pointer;

    &-text {
      font-size: 14px;
      color: #70707b;
    }
  }

  &__submit {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $greenBtn;
    border-radius: 99px;
    color: #121926;
    font-weight: 500;
    font-size: 18px;
    font-family: "Roobert", sans-serif;

    &:hover {
      background-color: $greenBtn;
    }

    &:hover:disabled {
      background: var(--rs-btn-default-disabled-bg);
    }

    &-block {
      flex-grow: 1;

      @media (min-width: 768px) {
        flex-grow: 0;
        display: flex;
        align-items: flex-end;
      }
    }
  }
}
