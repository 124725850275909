.notes {
  max-height: 260px;
  overflow: auto;
  &Item {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    padding: 10px;
    border-radius: 4px;
    align-items: center;
    border: 1px solid $borderColor;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    button {
      display: flex;
      padding: 5px;
    }
    svg {
      color: #51525c;
      width: 15px;
      height: auto;
    }
    &__text {
      margin-top: 10px;
      color: #344054;
    }
    &__date {
      font-size: 12px;
      color: #344054;
    }
  }
}
