.rs-picker-toggle-placeholder,
.rs-picker-value-item,
.rs-input-group-lg > .rs-input,
.rs-picker input::placeholder,
.rs-input-group.rs-input-group-lg > .rs-input-group-addon,
.rs-input::placeholder,
.rs-input-lg {
  font-size: 14px;
}
.rs-checkbox-label,
.rs-checkbox-control:before,
.rs-btn-close svg path,
.rs-checkbox-control:before,
.rs-checkbox-control .rs-checkbox-inner:before,
.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner:after {
  transition: none;
}

.rs-check-item.rs-check-item-focus,
.rs-check-item:focus,
.rs-check-item:not(.rs-checkbox-disabled):hover {
  background: none;
  color: black;
}

.rs-btn-close:hover svg path {
  stroke: none;
  fill: var(--rs-close-button-hover-color);
}

.rs-checkbox-label {
  font-weight: 400;
}

.rs-picker-caret-icon,
.rs-picker-toggle-indicator .rs-picker-clean.rs-btn-close {
  height: 21px !important;
}

// select picker
.rs-picker-clean .rs-icon {
  width: 13px;
  height: auto;
}
// select picker
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-clean {
  right: 12px;
}
.rs-tag-closable {
  .rs-icon {
    width: 12px;
    height: auto;
  }
}
.rs-btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
// .rs-tag-lg {
//   padding-top: 3px;
//   padding-bottom: 3px;
//   .rs-tag-icon-close {
//     padding-top: 0;
//     padding-bottom: 0;
//     height: 100%;
//   }
// }

.rs-picker {
  width: 100%;
}

.rs-input-group-disabled {
  .rs-input {
    cursor: not-allowed;
  }
}

.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
  min-width: auto;
  padding-right: 11px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 11px;
  padding-right: 11px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon {
  top: 10px;
}

.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-indicator .rs-picker-caret-icon {
  right: 9px;
}

.rs-picker-tag-lg .rs-picker-textbox .rs-picker-search-input {
  padding-left: 11px;
}
.rs-picker-tag {
  cursor: pointer;
}

.rs-avatar > .rs-avatar-image {
  object-fit: cover;
}

.rs-picker-popup {
  z-index: 10000;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #343434;
  font-size: 14px;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  font-weight: 400;
}

.rs-input-lg {
  height: 42px;
}

.rs-message-info {
  background: #f9f9fc !important;
  .rs-icon {
    color: #1570ef !important;
  }
}
.rs-message-container {
  background: transparent !important;
}

.MuiAccordionSummary-root {
  background: #f9f9fc !important;
}

.MuiAccordion-root {
  overflow: hidden;
  &.Mui-disabled {
    background: initial !important;
    opacity: 1;
  }
  // background: #fff !important;
}

// .MuiAccordion-heading {
//   background: #f9f9fc !important;
// }

.rs-picker-disabled-options .rs-picker-clean {
  right: 16px !important;
}
.rs-picker-disabled-options .rs-picker-toggle {
  padding-right: 36px !important;
}

.rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
  border-radius: 8px;
  transition: 0.2s;
  cursor: pointer;
}
.rs-uploader-text .rs-uploader-file-item-btn-remove {
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.rs-uploader-text .rs-uploader-file-item {
  background: transparent !important;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}
.rs-uploader-text .rs-uploader-file-item-icon-wrapper {
  position: static;
  margin-right: 5px;
  width: auto;
}
.rs-uploader-text .rs-uploader-file-item-panel {
  width: 100%;
  overflow: hidden;
}

.rs-uploader-text .rs-uploader-file-item-btn-remove {
  position: static;
  padding: 3px;
}

.rs-input {
  font-family: "Inter", sans-serif;
}

.rs-input-group {
  svg {
    width: 18px;
    height: auto;
  }
}

.rs-input-group.rs-input-group-inside .rs-input-group-btn {
  background: #eff3f8;
  &:hover {
    background: #e3e7eb;
  }
}
