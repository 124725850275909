.users {
}

.usersStats {
  display: grid;
  grid-gap: 12px;
  &__item {
    background: #f2f4f7;
    border-radius: 4px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 78px;
  }
  .title-sm {
    font-weight: 400;
  }
}

.filters-block {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 12px;
  &-item {
    width: 100%;
    max-width: 316px;
    .rs-picker {
      width: 100% !important;
    }
  }
  &-users {
    display: flex;
    max-width: 400px;
  }
  &-drills {
    justify-items: flex-end;
  }
  &-videos {
  }
  &-actions {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.userHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  margin-bottom: 24px;
  &__avatar {
    margin-right: 24px;
    img {
      object-fit: cover;
    }
  }
  &__name {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      color: #344054;
      font-size: 12px;
      opacity: 0.7;
      margin-left: 5px;
      svg {
        width: 14px;
        height: auto;
      }
    }
  }
  &__state {
    display: flex;
    align-items: center;
    .divider {
      width: 4px;
      height: 4px;
      background: #d0d5dd;
      margin: 0 8px;
      border-radius: 50%;
    }
  }
  &__country {
    color: #475467;
    display: flex;
    align-items: center;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    display: flex;
    gap: 12px;
  }
  &__drill {
    padding: 0 12px;
    display: flex;
    align-items: center;
    font-weight: 500;
    &:not(:last-child) {
      color: #4b5565;
      &:hover {
        text-decoration: underline;
      }
    }
    svg {
      margin-right: 8px;
      width: 20px;
      height: auto;
    }
    &-icon {
      color: #4b5565;
    }
  }
}

.user-state {
  padding: 4px 6px;
  background: #d3f8df;
  color: #087443;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
}

.userNav {
  border-bottom: 1px solid #eaecf0;
  &.nav-drills {
    margin-bottom: 24px;
  }
  &__row {
    display: flex;
    a,
    button {
      height: 46px;
      padding: 0 18px;
      display: flex;
      align-items: center;
      color: #344054;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      transition: 0.25s;
      white-space: nowrap;
      &.active {
        background: #eef2f6;
        color: black;
      }
      &:hover {
        color: black;
      }
    }
  }
}

.userId {
  .usersStats {
    border-bottom: 1px solid #eaecf0;
  }
}

.personalDetails {
  &__list {
    display: grid;
    grid-gap: 8px;
  }
  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    align-items: center;
    p {
      color: #475467;
      span {
        color: black;
        font-weight: 500;
      }
      &:last-child {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      a {
        color: $linkColor;
      }
    }
  }
}

.userIdGroup {
  display: grid;
  padding-top: 24px;
  border-bottom: 1px solid #eaecf0;
  .title-sm {
    margin-bottom: 16px;
  }
  &__block {
    .title-sm {
      border-bottom: 1px solid #eaecf0;
      padding-bottom: 8px;
    }
    padding-bottom: 16px;
    &.border-right {
      border-right: 1px solid #eaecf0;
    }
  }
}

.skills {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  &__item {
    background: #eaecf0;
    min-height: 103px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    line-height: 1;
    &.mr {
      margin-right: 6px;
    }
    &-text {
      font-size: 18px;
    }
    &-value {
      font-size: 34px;
      font-weight: 600;
    }
    &-block {
      font-weight: 500;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
    }
  }
  &__status {
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 90px;
    text-transform: capitalize;
    background: #eef2f6;
    color: #0e0f11;
    white-space: nowrap;

    &.ml {
      margin-left: 6px;
    }
    &.bad {
      box-shadow: 0px 1px 2px 0px #1018280f;
      background: rgba(244, 67, 54, 0.6);
    }
    &.average {
      box-shadow: 0px 1px 2px 0px #1018280f;
      background: rgba(255, 193, 7, 0.6);
    }
    &.good {
      box-shadow: 0px 1px 2px 0px #1018280f;
      background: rgba(139, 195, 74, 0.6);
    }
    &.very-good {
      box-shadow: 0px 1px 2px 0px #1018280f;
      background: rgba(76, 175, 80, 0.6);
    }
  }
}

.similarPlayersList {
  .leaderList__item {
    &:first-child {
      border-top: 0;
    }
  }
}

.userCoachChats {
  height: 100%;
  max-height: 382px;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    height: 57px;
  }
}
