$screen-lg-max: 1210px;
$screen-md-max: 991px;
$screen-sm-max: 650px;

@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}
