@font-face {
  font-family: "Roobert";
  src: url("../fonts/Roobert-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("../fonts/Roobert-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("../fonts/Roobert-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
