.authCode {
  display: flex;
  justify-content: center;

  &-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 6px;

    @media (min-width: 640px) {
      gap: 12px;
    }
  }

  input {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 80px;
    width: 100%;
    font-size: 48px;
    color: #18181b;
    text-align: center;
    font-weight: 500;
    flex-shrink: 0;
    flex-grow: 0;

    &:focus {
      border-color: #18181b;
      box-shadow: 0px 0px 0px 4px #ededed, 0px 1px 2px 0px #101828;
      outline: none;
    }

    &::placeholder {
      color: #d0d5dd;
    }

    &:not(:last-child) {
    }
  }
}
