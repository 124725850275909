.filter-label {
  font-size: 14px;
  color: #51525c;
  margin-bottom: 6px;
}

.filter-age {
  max-width: 120px;
}

.filter-age-block {
  display: flex;
  gap: 6px;
}

body {
  .MuiMenuItem-root {
    font-size: 14px;
    font-family: "Inter", sans-serif;
  }
  .MuiMenu-list {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.state-select {
  &:before,
  &:after {
    display: none;
  }
  .MuiSelect-select {
    font-size: 20px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    padding: 0;
    display: flex;
    align-items: center;
  }
}
