.metrics {
  &__expand {
    padding-top: 12px;
    padding-bottom: 12px;
    .MuiAccordion-root {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
    .MuiAccordionDetails-root {
      p {
        &:not(:first-child) {
          margin-top: 6px;
        }
      }
    }
  }
  &__scoring {
    margin-top: 6px;
    &-item {
      &:not(:first-child) {
        margin-top: 6px;
      }
    }
    &-title {
      margin-bottom: 6px;
    }
  }
  &Rule {
    &__title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      background: #e3e8f0;
      border-radius: 5px;
      padding: 10px;
    }
    &__group {
      // padding: 12px;
      // border-radius: 8px;
      // background: #f7f7fa;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &-title {
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }
}

.scoringItem {
  padding: 10px;
  border: 1px solid #e3e8f0;
  border-radius: 5px;
  background: #f1f4f9;
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  .rs-tag-text {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 18px;
      height: auto;
      margin-left: 6px;
    }
  }
  &__fields {
    margin-top: 15px;
  }
  &__title {
    // font-size: 16px;
    font-weight: 700;
  }
}
