.editProfile {
  &__header {
    font-size: 18px;
    font-weight: 500;
    color: #344054;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eaecf0;
    a {
      margin-right: 12px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e3e8ef;
      svg {
        color: #344054;
        width: 80%;
        height: auto;
      }
    }
  }
  &__group {
    display: grid;
    grid-template-columns: 280px 1fr;
    grid-gap: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eaecf0;
    .auth__form-label {
      font-size: 14px;
    }
    &-label {
      color: $greyTitle;
      font-weight: 500;
    }
    .max-width {
      max-width: 512px;
    }
    &-fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
  }
  &__btns {
    display: flex;
    justify-content: flex-end;
    button {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.attachmentUpload {
  width: 100%;
  text-align: center;
  position: relative;
  &.hidden {
    visibility: hidden;
    display: none;
  }
  input {
    display: none;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #f2f4f7;
    border: 6px solid #f9fafb;
    margin: 0 auto;
    svg {
      color: #475467;
      width: 70%;
      height: auto;
    }
  }
  &__text {
    font-size: 14px;
    color: #667085;
    text-align: center;
    button {
      color: #000;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__label {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #fff;
    padding: 16px;
    cursor: pointer;
    &.drag-active {
      background: #f5f5f5;
      border-style: dotted;
    }
  }
}

.attachmentPreview {
  display: flex;
  align-items: center;
  margin-top: 10px;
  &__btns {
    display: flex;
    button:first-child {
      margin-right: 10px;
    }
  }
  &__img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
  }
}
