.drillsOverview {
  &__block {
    display: grid;
    grid-template-columns: 170px 1fr;
    grid-gap: 15px;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid #cdd5df;
      margin-bottom: 24px;
      padding-bottom: 24px;
    }
    &-list {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(3, 1fr);
    }
    &-value {
      font-size: 18px;
      font-weight: 500;
      color: #121926;
    }
    &-small {
      color: #344054;
    }
  }
  &__item {
    background: #eef2f6;
    border-radius: 8px;
    padding: 12px;
    display: grid;
    grid-gap: 10px;
  }
  &__video1 {
    width: 100%;
    height: auto;
    max-height: 220px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 6px;
  }
  &__video {
    width: 50%;
    video {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }
  &__version {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  &__details {
    margin-top: 15px;
    display: grid;
    grid-gap: 5px;
    &-item {
      padding: 10px 15px;
      font-size: 12px;
      background: #f8f9fa;
      border-radius: 20px;
    }
    &-top {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 15px;
      align-items: center;
    }
    &-progress {
      margin-top: 5px;
      height: 5px;
      width: 100%;
      background: #e0e4e8;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      div {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background: #bac3cc;
      }
    }
  }
  &__highlights {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    video {
      width: 100%;
      min-height: 300px;
      object-fit: cover;
    }
    &-text {
      margin-top: 10px;
      text-transform: capitalize;
    }
  }
}

.drillsCsv {
  &__downloadBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #eef2f6;
    svg {
      width: 60%;
      height: auto;
      color: black !important;
    }
  }
  &__log {
    white-space: break-spaces;
  }
  &__expand {
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  &__collapse {
    .userNav__row {
      button {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
    &-download {
      font-weight: 600;
      text-decoration: underline;
      width: max-content;
      margin-bottom: 15px;
      display: block;
      color: blue;
    }
    &-btns {
      display: flex;
      align-items: center;
      button {
        margin-right: 20px;
      }
    }
  }
}

.drill-status {
  padding: 2px 6px;
  border-radius: 90px;
  background: #eef2f6;
  color: #364152;
  white-space: nowrap;
  text-align: center;
  font-size: 13px;
  &.uploaded {
    background: #abe1f8;
  }
  &.processing {
    background: #f7f8ab;
  }
  &.processed {
    background: #d0f8ab;
  }
}

.drill-toggle {
  svg {
    width: 40px;
    height: auto;
  }
  svg {
    color: #b1df86 !important;
  }
  &.active {
    svg {
      color: #596578 !important;
    }
  }
}

@include lg {
  .drillsOverview__block-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
