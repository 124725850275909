.hlights {
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
  }
  &__item {
    video {
      border-radius: 4px;
      height: 190px;
      object-fit: cover;
      margin-bottom: 12px;
    }
  }
}
