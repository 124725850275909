$linkColor: #1570ef;
$greenBtn: #24ff00;
$greyTitle: #344054;
$green: #039855;
$borderColor: #d0d5dd;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
  min-height: 100vh;
  color: black;
}

a,
a:hover {
  text-decoration: none;
}

button {
  background: transparent;
  box-shadow: none;
  border: 0;
  outline: none;
  cursor: pointer;
  font-family: "Inter", sans-serif;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

textarea {
  resize: none !important;
  font-family: "Inter", sans-serif;
  &:focus {
    outline: none !important;
  }
}

.error-text {
  color: #e73f3f;
  font-size: 14px;
}

.success-icon {
  width: 108px;
  height: 108px;
  background: #ecfdf3;
  border: 2px solid #d1fadf;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &-inner {
    width: 55%;
    height: 55%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #62ca7a;
    svg {
      font-size: 2em;
      color: #fff;
      width: 60%;
      height: 60%;
    }
  }
}

.text-grey {
  color: $greyTitle;
}
.text-right {
  text-align: right;
  .page-block-percent {
    justify-content: flex-end;
  }
}

.todo {
  background: rgb(255, 167, 167) !important;
}

// todo: fix later

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.text-center {
  text-align: center;
}
.text-greyTitle {
  color: $greyTitle;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.font-medium {
  font-weight: 500;
}
.justify-end {
  justify-content: flex-end;
}
.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-rows-auto-1fr {
  grid-template-columns: auto 1fr;
}
.grid {
  display: grid;
}
.grid-cold-1fr-auto {
  grid-template-columns: 1fr auto;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-23 {
  margin-top: 23px;
}
.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}
.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.border-none {
  border: 0 !important;
}
.text-linkColor {
  color: $linkColor !important;
}
.mt-18 {
  margin-top: 18px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-8 {
  margin-top: 8px;
}
.overflow-text-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-break: anywhere;
}
