.chats {
  &__list {
    display: grid;
    grid-gap: 10px;
    video {
      width: 100%;
      margin: 15px 0;
    }
  }
  &__item {
    border-radius: 6px;
    background: #f2f4f7;
    max-width: 700px;
    width: 100%;
    padding: 10px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    &-header {
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      margin-bottom: 5px;
    }
    &.user-style {
      background: #d1fadf;
      grid-template-columns: 1fr auto;
      margin-left: auto;
      .rs-avatar {
        order: 2;
      }
    }
    table,
    th,
    td {
      border: 1px solid #c6c6c6;
    }
    table {
      margin: 10px 0;
      border-collapse: collapse;
      width: 100%;
      th,
      td {
        text-align: left;
        padding: 3px;
      }
    }
    ul,
    ol {
      margin: 10px 0;
      li {
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    ul {
      padding-left: 12px;
    }
    ol {
      padding-left: 14px;
    }
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 17px;
    }
    p {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
  &__filters {
    display: grid;
    grid-template-columns: auto auto 1fr;
    justify-items: flex-end;
    grid-gap: 20px;
    align-items: flex-end;
  }
}
