.dashboard {
  display: grid;
  grid-gap: 12px;
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
}

.leaderList {
  &__item {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid #eeeef2;
    display: grid;
    grid-template-columns: auto 2fr 1fr 1fr auto;
    grid-gap: 18px;
    align-items: center;
    transition: 0.15s;
    &:hover {
      background: #f5f5f5;
    }
    p {
      color: black;
    }
  }
  &__img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
}

.drills {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
  margin-bottom: 12px;
  &__item {
    height: 102px;
    border: 1px solid #d0d5dd;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
  &__title {
    display: flex;
    align-items: center;
    &-color {
      margin-right: 4px;
      width: 8px;
      height: 4px;
      border-radius: 21px;
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f4f7;
    border-radius: 50%;
    margin-left: 8px;
    svg {
      font-size: 13px;
    }
  }
}

.signUpStatus {
  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    &:not(:last-child) {
      border-bottom: 1px solid #d0d5dd;
    }
    &:first-child {
      padding-top: 0;
    }
  }
}

.coach {
  grid-template-columns: repeat(3, 1fr);
}

.player-role-chart {
  .apexcharts-tooltip-text-y-label,
  .apexcharts-tooltip-text-y-value {
    color: black;
  }
}

.coach-chart .apexcharts-data-labels text {
  fill: black;
}
