.sessionsTableRow {
  cursor: pointer;
  &.expanded {
    .journeyCell__more {
      display: none;
    }
    .journeyCell__left-list {
      flex-wrap: wrap;
      width: auto;
      .rs-tag:last-child {
        display: flex;
      }
    }
    .journeyCell__item {
      &-last {
        display: none;
      }
      &-close {
        display: block;
      }
    }
  }
}

.journeyCell {
  width: 75%;
  max-width: 300px;
  &__block {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 17px;
    position: relative;
    &.one-line {
      display: flex;
      grid-gap: 0;
    }
    .journeyCell__item-close {
      display: none;
    }
  }
  &__left {
    overflow: hidden;
    position: relative;
    &-list {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: max-content;
      .rs-tag:last-child {
        display: none;
      }
    }
  }
  &__more {
    text-align: right;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 82%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    button {
      display: flex;
      align-items: center;
    }
    &.hidden {
      display: none;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    .MuiSvgIcon-root {
      margin: 0 5px;
      width: 20px;
      height: auto;
    }
  }
  .rs-tag {
    margin: 3px 0;
    white-space: nowrap;
    background: #eaecf0;
    color: black;
  }
}
