.prompts {
  &__form {
    .auth__form-label {
      font-size: 14px;
    }
    &-template {
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      grid-gap: 15px;
      background: #f3f5f8;
      padding: 10px;
      border-radius: 5px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      .red-outlined-btn {
        margin-top: 23px;
        svg {
          width: 17px;
        }
      }
    }
    .rs-message-warning {
      background: #ffc757;
    }
    .rs-message-container {
      padding: 12px;
      font-size: 12px;
      strong {
        display: block;
        margin-bottom: 6px;
      }
    }
  }
  &__log {
    padding: 12px 24px;
    width: 100%;
    textarea {
      color: black !important;
      margin-top: 6px;
    }
    &-item {
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-top: 12px;
      &.active {
        background: #f2fff2;
        textarea {
          background: #fff;
        }
      }
    }
    &-group {
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-gap: 30px;
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 6px;
      }
      span {
        font-weight: 600;
      }
    }

    &-select {
      max-width: 300px;
      margin-bottom: 12px;
    }
  }
  &__tags {
    margin-top: 10px;
  }
}

// .expand-row .MuiTableCell-root {
//   padding-left: 12px;
//   padding-right: 12px;
// }

.promptsTableToolbar {
  &.has-border {
    border-top: 1px solid #eaecf0;
  }
  &.MuiToolbar-root {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    align-items: center;
    grid-gap: 30px;
  }
  &__title {
    font-size: 18px;
    font-weight: 600;
  }
}
