.settings {
  display: grid;
  grid-gap: 16px;
  .title-sm {
    margin-bottom: 18px;
  }
  &__profile {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto 1fr;
    .rs-avatar {
      background: #0091ff;
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-name {
      font-weight: 600;
      font-size: 20px;
    }
    &-position {
      color: #51525c;
    }
    &-email {
      margin-top: 10px;
      color: $linkColor;
      font-weight: 500;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  &__password {
    &-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
    }
    &-text {
      color: #344054;
    }
  }
  &__sec {
    &-heading {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      svg {
        width: 27px;
        height: auto;
        margin-right: 10px;
      }
    }
    &-title {
      font-size: 20px;
      font-weight: 600;
    }
    &-text {
      color: #51525c;
    }
    &-list {
      display: grid;
      grid-gap: 10px;
      margin-top: 20px;
    }
    &-item {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-gap: 16px;
      align-items: center;
      border: 1px solid $borderColor;
      border-radius: 8px;
      padding: 16px;
      svg {
        width: 40px;
        height: auto;
      }
      &.add {
        cursor: pointer;
        svg {
          color: #b0b0b0;
          border-radius: 50%;
        }
      }
    }
    &-content {
      p {
        &:first-child {
          font-size: 16px;
          color: #101828;
          margin-bottom: 4px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
        &:last-child {
          color: $greyTitle;
        }
      }
    }
    &-status {
      padding: 3px 10px;
      border-radius: 30px;
      font-size: 12px;
      margin-left: 10px;
      &.active {
        color: #005a43;
        background: #aee9d1;
      }
      &.inactive {
        background: #f1f1f1;
        color: #4a4a4a;
      }
    }
  }
  &__act {
    .settings__sec-item {
      grid-template-columns: 1fr auto;
    }
  }
}

@include sm {
  .settings__profile-avatar {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-items: flex-end;
    align-items: flex-start;
    .black-btn {
      margin-top: 0;
    }
  }
  .settings__profile {
    grid-template-columns: 1fr;
  }
}
