.sidebar {
  .MuiPaper-root {
    padding-top: 62px;
    z-index: 1;
    background: #2c2c2c;
    border-right: none;
  }
  &__icon {
    color: #101828 !important;
    width: 44px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    transition: 0.2s;
    &:hover,
    &.active {
      background: #fff;
    }
  }
  &__text {
    opacity: 0;
    color: #101828;
    &.active {
      opacity: 1;
    }
  }
  &__list {
    background: #e3e8ef;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__m {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px 6px;
      button {
        display: flex;
      }
    }
  }
  .header__user {
    margin-left: 0;
    &-icon {
      width: 40px;
      height: 40px;
      font-size: 14px;
    }
  }
}

@include md {
  .sidebar {
    .MuiListItemButton-root {
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .MuiPaper-root {
      padding-top: 0;
      max-width: 325px;
      width: 100%;
      background: #e3e8ef;
    }
  }
}
