.header {
  height: 62px;
  background: #2c2c2c;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 10;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  justify-items: flex-end;
  &__left {
    display: flex;
    align-items: center;
    img {
      margin-left: 27px;
    }
  }
  &__menuBtn {
    margin-left: 2px;
    svg {
      fill: white;
    }
  }
  &__right {
    display: flex;
    align-items: center;
  }
  &__search {
    margin-right: 100px;
  }
  &__user {
    margin-left: 16px;
    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      background: #0091ff;
      text-transform: uppercase;
      color: #fff;
      font-weight: 600;
    }
  }
}

@include md {
  .header {
    .header__user {
      display: none;
    }
  }
  .header__menuBtn {
    margin-left: 0;
  }
}
