.title-sm {
  font-size: 14px;
  font-weight: 500;
}

.main-layout {
  min-height: 100vh;
}

.main-content {
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 62px;
  height: 100%;
  &-title {
    font-size: 18px;
    font-weight: 500;
    color: #101828;
    margin-bottom: 24px;
  }
  &-px {
    padding-left: 24px;
    padding-right: 24px;
    @include md {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.page-block {
  border: 1px solid #d0d5dd;
  background: white;
  border-radius: 8px;
  padding-top: 18px;
  padding-bottom: 18px;
  overflow: hidden;
  &.pb-0 {
    padding-bottom: 0;
  }
  &-pd {
    padding-left: 18px;
    padding-right: 18px;
    @include md {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &-value {
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 18px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    @include sm {
      font-size: 18px;
    }
  }
  &-percent {
    color: $green;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  &-link {
    font-size: 12px;
    color: $linkColor;
  }
}

.copy-icon {
  display: flex;
  margin-left: 5px;
  width: max-content;
  cursor: pointer;
  color: #70707b;
  &:hover {
    color: $linkColor;
  }
  svg {
    width: 17px;
    height: auto;
  }
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}
